
import data_sa from "../data/supervisory-authorities"
import zipToSA from "../data/german-zip-code.json"

import * as ics from 'ics'
import moment from 'moment'
import { SupervisoryAuthority } from "../data/supervisory-authorities.d"

export const noSAfound = (data_sa.find(e => e.slug === "notfound") as SupervisoryAuthority);

export function joinArray (source: string[], and: string) {
    let res = "";
    for (let n = 0; n < source.length; n++) {
        if (n > 0 && source.length - n > 1 && source.length > 1) res += ", ";
        else if (source.length-n === 1 && source.length>1) res += " " + and + " ";
        res += source[n];
    }
    return res;
}

export const getSA = (county: string, address: string, isBFDI: boolean) => {

    let found: SupervisoryAuthority | null | undefined = noSAfound;

    if (isBFDI) {
        return (data_sa.find(e => e.slug === "debfdi") as SupervisoryAuthority)
    }

    if (county === "de") {

        let zipCode: any = address.match(/[0-9]{5}/);
        zipCode = parseInt((zipCode) ? zipCode[0] : "0");
    
        zipToSA.forEach((element) => {
            if (element.from <= zipCode && element.to > zipCode) {
                found = (data_sa.find(e => e.slug === element.dsb_slug) as SupervisoryAuthority);
            }
        });

    }


    return found;
        
}

export function showWhen (condition: boolean, el: JSX.Element): JSX.Element | null {
	return (condition) ? el : null;
}

export const getHostname = (url: string) => {
    try {
        const urlParts = url.replace('http://','').replace('https://','').split(/[/?#]/);
        return urlParts[0]
    } catch (error) {
        return "";        
    }
}

export const downloadFile = (name: string, body: string, content: string = "text/calendar") => {

    var element = document.createElement('a');
    element.setAttribute('href', `data:${content};charset=utf-8,` + encodeURIComponent(body));
    element.setAttribute('download', name);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);

}

export const copy = (str: string) => {
    if (navigator.clipboard) navigator.clipboard.writeText(str);
}

function replaceLast (str: string, find: string, replace: string) {
    var index = str.lastIndexOf(find);

    if (index >= 0) {
        return str.substring(0, index) + replace + str.substring(index + find.length);
    }

    return str.toString();
}

export const convertArrayToOrList = (list: string[]): string => {

    const listStr = list.join(", ");

    return replaceLast(listStr, ", ", ' oder ');

}

export function generateICSFile (t: any, trackerUrl: string, location: string): void {

    const domain = getHostname(trackerUrl);

    const event: any = {
        start: moment().add({ 'weeks': 4 }).format("YYYY-M-D-H-m").split("-"),
        duration: { minutes: 30 },
        title: t("generator.reminder-title", { domain }),
        description: t("generator.reminder-desc", { domain }),
        location
    }
       
    ics.createEvent(event, (error, value) => {
        if (!error) downloadFile(t("generator.reminder-filename", { domain }) + ".ics", value);
    })

}

export function copyToClipboard (message: string) {

    if (navigator.clipboard) 
        navigator.clipboard.writeText(message);    

}

export function scrollAndFocus (hash: string, element: React.MutableRefObject<any>) {
    
    (window as any).location.hash = "#" + hash;
    setTimeout(() => {
        element.current?.focus();
    }, 300); // scroll-behavior: smooth;

}

export function openEmailProgram (email: string, subject: string, body: string) {

    (window as any).location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

}