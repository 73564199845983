import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';

import WebPages from "./WebPages"
import AndroidApp from "./AndroidApp"
import { RadioButton } from "./components/radiobutton"

import './utils/i18n';
import "./assets/style/main.css"
import "./assets/style/tailwind.build.css"

import { useTranslation } from 'react-i18next'
import { h3class } from './assets/style/clasnames';
import { showWhen } from './utils/utils';

let firstLoad = true;

function App () {

    const [t] = useTranslation();

    const [type, setType] = useState("webpage");
    const [showGeneratedText, setShowGeneratedText] = useState(false);

    useEffect(()=>{

        if (firstLoad) {
            firstLoad = false;

            try {

                const savedData = JSON.parse(atob((window as any).location.hash.replace("#", "")));

                switch (savedData.id) {
                    case "r-web": setType("webpage"); break;
                    case "r-app": setType("app"); break;
                }
            
            } catch (error) { }

            return;
        }

        try {
            JSON.parse(atob((window as any).location.hash.replace("#", "")));
        } catch (error) {
            (window as any).location.hash = (showGeneratedText) ? "nachricht" : "generator";
        }

    }, [showGeneratedText])

    return (

        <>
            {((showGeneratedText) ? null : (
                <>
                    <h3 className={h3class}>{t("generator.title-for-what")}</h3>

                    <div className="sm:flex sm:space-x-8 my-4">
                        <RadioButton onChange={setType} value="webpage" label={t("generator.type-webpage")} checkedValue={type} />
                        <RadioButton onChange={setType} value="app" label={t("generator.type-app")} checkedValue={type} />
                    </div>
                </>
            ))}

            {showWhen(type === "webpage", (
                <WebPages showGeneratedText={showGeneratedText} setShowGeneratedText={setShowGeneratedText} />
            ))}
            {showWhen(type === "app", (
                <AndroidApp showGeneratedText={showGeneratedText} setShowGeneratedText={setShowGeneratedText} />
            ))}

        </>

    )

}

import { createRoot } from 'react-dom/client';
const container = document.getElementById('generator');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);