import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { h3class, inputClass, inputErrorMsgClass, pClass } from './assets/style/clasnames';
import { getDefaultTemplateComplaintOptions, useSelectTracker, useDefaultGenerator, sortTracker } from './ComplaintGenerator';
import { joinArray, scrollAndFocus, showWhen } from './utils/utils';

import CheckBoxes from "./components/checkboxes"

import appTracker from "./data/tracker-app"

import Template from "letter-generator/Template"
import i18n from './utils/i18n';
import { AutoCompleteInput } from './components/AutoCompleteInput';
import { DynamicInputs } from './components/DynamicInputs';
import Validation from './components/Validation';

export interface InputItem {
	slug: string,
	connectionTo: string,
	lastChanged: boolean | number,
	sendingId: string[]
}

let isFirstLoad = true;

export default function (props: {
	showGeneratedText: any,
	setShowGeneratedText: any
}) {

	i18n.setDefaultNamespace("app");

    const [t] = useTranslation();

	const d = useDefaultGenerator();
	const appVersionRef = useRef((null as any));

	const [isAppVersionValid, setIsAppVersionValid] = useState(true);
	const [alreadyTriedToGenerate, setAlreadyTriedToGenerate] = useState(false);

	const [loadedFromUrl, setLoadedFromUrl] = useState(false);
	const [moreOptions, setMoreOptions] = useState(([] as string[]));
	const [appVersion, setAppVersion] = useState("");
	const [testetTrackersConnection, setTestetTrackersConnection] = useState(([] as string[]));
	const [testetTrackersId, setTestetTrackersId] = useState(([] as string[]));
	const [testetTrackersData, setTestetTrackersData] = useState(([] as {
		slug: string,
		connectionTo: string,
		lastChanged: boolean | number,
		sendingId: string[]
	}[]));

	if (testetTrackersData.length === 0) {
		setTestetTrackersData(appTracker.map(tracker => {return {
			slug: tracker.slug,
			connectionTo: "",
			lastChanged: false,
			sendingId: [ "" ]
		}}))
	}

	function hasTested () {

		return (testetTrackersData.find(e => e.connectionTo !== "")) ? true : false;
		
	}

    const {
		SelectTracker,
		checkedTracker,
		setCheckedTracker,
		customTrackerValues,
		setSelectedTrackerIsValid
	} = useSelectTracker(appTracker, loadedFromUrl, (
		<ul>

			<li><a className="font-medium text-1xl underline" href={"https://reports.exodus-privacy.eu.org/en/reports/" + getAppNameFromUrl() + "/latest/"} target="_blank" rel="noopener noreferrer">
				{t("generator.exocud-privacy")}
			</a></li>
			<li><a className="font-medium text-1xl underline" href="https://appcheck.mobilsicher.de/filter" target="_blank" rel="noopener noreferrer">
				{t("generator.mobilsicher")}
			</a></li>
			<br />
		</ul>
	));

	function getAppNameFromUrl (): string {

		return d.inputUrl.split("id=")[1] || d.inputUrl;

	}

	function updateTrackerText (trackerText: string) {

		let newText = trackerText.split("\n\n");

		let trackers = appTracker.filter(tracker => checkedTracker.find(slug => slug === tracker.slug));

		newText.forEach((_, index) => {

			if (!trackers[index]) return;

			let found = testetTrackersData.find(data => data.slug === trackers[index].slug);
			if (found && (found.connectionTo !== "" || trackers[index]["connections-to"].length === 1)) {

				newText[index] += "\n" + t("generator.connection-to-text", {
					connectionIP: (found.connectionTo !== "") ? found.connectionTo : trackers[index]["connections-to"][0],
					trackerName: trackers[index].name
				});

			}
			let sendingids = found?.sendingId.filter(e => e !== "") || [];
			if (found && sendingids.length > 0 ) {

				newText[index] += "\n" + t("generator.sending-id-" + ((d.isComplaint()) ? "complaint" : "request"), {
					"sending-ip": joinArray(sendingids, t("ge­ne­rell.common.and"))
				});

			}

		})

		return newText.join("\n\n");
	}

	function getRequestText (): string {

		const templateText = d.getTemplate()["app-tracking-request"];
		let defaults = d.getDefaultTemplateOptions(appTracker, checkedTracker, customTrackerValues);

		const variables = {
			...defaults.variables,
			appName: getAppNameFromUrl(),
			trackerText: updateTrackerText(defaults.variables.trackerText)
		}

		const flags = {
			...defaults.flags,
			hasTested: hasTested()
		}

		return new Template(templateText, flags, variables).getText()// for scrolling

	}

	function getComplaintText (): string {

		const templateText = d.getTemplate()["app-tracking-complaint"];
		const defaults = d.getDefaultTemplateOptions(appTracker, checkedTracker, customTrackerValues);

		const variables = {
			...defaults.variables,
			lastCheckDate: d.lastCheckDate,
			addressSiteOperator: d.addressSiteOperator,
			trackerCount: checkedTracker.length,
			appVersion: appVersion,
			trackerText: updateTrackerText(defaults.variables.trackerText)
		}

		const flags = {
			...defaults.flags,
			...getDefaultTemplateComplaintOptions(d.checkedDisclosureOptions),
			operatorHasConceded: moreOptions.indexOf("operatorHasConceded") > -1,
			hasTested: hasTested()
		};

		return new Template(templateText, flags, variables).getText();

	}

    function getGeneratedText () {
        return (d.isComplaint()) ? getComplaintText() : getRequestText();
	}
	

	// -- Input Validation -- 

	const isInputUrlValid = () => d.inputUrl.length > 12;
	const isAddressSiteOperatorValid = () => d.addressSiteOperator.indexOf("\n") !== -1 || d.selectedDSB.slug !== "notfound";


    function checkAndShowGeneratedText () {

		if (!isInputUrlValid()) {
			d.setInputUrlIsValid(false);
			return scrollAndFocus("insert-url", d.inputUrlRef);
		}

		if (d.consentType === "ja") {
			d.setConsentTypeIsValid(false);
			return (window as any).location.hash = "#content-type"
		}

		if (checkedTracker.length === 0) {
			setSelectedTrackerIsValid(false);
			return (window as any).location.hash = "#select-tracker"
		}

		if (d.isComplaint()) {

			if (appVersion.length === 0) {
				setIsAppVersionValid(false);
				return scrollAndFocus("app-version", appVersionRef);
			}

			if (!isAddressSiteOperatorValid()) {
				d.setAddressSiteOperatorIsValid(false);
				return scrollAndFocus("site-operator", d.siteOperatorRef);
			}

			if (!hasTested()) {
				setAlreadyTriedToGenerate(true);
				return (window as any).location.hash = "#checks"
			}

		}

		if (d.consentType !== "ja") {

			// The statistics should not be counted up every time a change is made.
			let savedAppIDs = localStorage.getItem("generated-apps")?.split(",") || [];
			let appid = getAppNameFromUrl() + "-" +((d.isComplaint()) ? "complaint" : "request");

			if (!savedAppIDs.find(e => e === appid)) {
				savedAppIDs.push(appid);
				localStorage.setItem("generated-apps", savedAppIDs.join(","));

				let data = {
					"type": "app",
					"data": {
						"app_id": (d.includeInTracktorList) ? getAppNameFromUrl() : "[hidden]",
						"app_version": (d.includeInTracktorList) ? appVersion : "[hidden]",
						"action": (d.isComplaint()) ? "complaint" : "request",
						"tracker_slugs": checkedTracker,
						"supervisory_authority_slug": d.selectedDSB?.slug
					}
				}

				fetch(`${(window as any).location.origin}/statistics/api.php`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}).then(e => e.json()).then(e => console.log(e))

			}

		}

		props.setShowGeneratedText(true);

	}

	useEffect(()=> {

		if (isInputUrlValid()) {
			d.setInputUrlIsValid(true);
		}
		if (isAddressSiteOperatorValid()) {
			d.setAddressSiteOperatorIsValid(true);
		}
		if (d.consentType !== "ja") {
			d.setConsentTypeIsValid(true);
		}
		if (appVersion.length > 0) {
			setIsAppVersionValid(true);
		}

	}, [d.inputUrl, d.addressSiteOperator, d.consentType, appVersion])
	

	if (isFirstLoad) {
		isFirstLoad = false;
		try {

			const savedData = JSON.parse(atob((window as any).location.hash.replace("#", "")));
			if(savedData.id === "r-app") {
				d.restore(savedData);
				setCheckedTracker(savedData.d);
				setLoadedFromUrl(true);
				setMoreOptions(savedData.e);
				setTestetTrackersConnection(savedData.f);
				setTestetTrackersId(savedData.g);
				setTestetTrackersData(savedData.h);
				setTimeout(() => {
					(window as any).location.hash = "generator";
				}, 100);
			}

		} catch (error) {
			console.log(error);
		}
	}

	function getSavedUrl () {

		return `${(window as any).location.protocol}//${(window as any).location.host}/#${btoa(JSON.stringify({
			id: "r-app",
			a: d.complaintCountry,
			b: d.consentType,
			c: d.inputUrl,
			d: checkedTracker,
			e: moreOptions,
			f: testetTrackersConnection,
			g: testetTrackersId,
			h: testetTrackersData
		}))}`;

	}

    const GeneratedText = d.useGeneratedText(getGeneratedText, props.setShowGeneratedText, getSavedUrl, {
		withDeadline: hasTested(),
		subjectName: getAppNameFromUrl()
	});

	function onBlur () {
		setTimeout(() => {
			if ((document as any).activeElement.tagName === "INPUT") return;
			setTestetTrackersData(testetTrackersData.map(e => {
				return { ...e, lastChanged: false }
			}))
		}, 10);
	}
	function updateTestetTrackersData (slug: string, value: string, index: number) {

		let b = testetTrackersData.map(e => {
			e.lastChanged = false;
			if (e.slug === slug) {
				e.sendingId[index] = value;
				e.lastChanged = index;
			}
			return e;
		});

		testetTrackersData.forEach(e => {
			let empty = e.sendingId.filter(e => e === "");
			if (empty.length === 0) {
				e.sendingId.push("");
			}
			while (empty.length > 1) {
				let indexOf = e.sendingId.indexOf("");
				e.sendingId.splice(indexOf, 1);
				empty = e.sendingId.filter(e => e === "");
			}
		})

		setTestetTrackersData(b);

	}

    if (props.showGeneratedText) {
        return GeneratedText;
	}

	const selectedTracker = appTracker.filter(e => checkedTracker.find(f => e.slug === f));

    return (
        <>

            {d.SelectType}

            {d.InputUrl}

			{showWhen(d.isComplaint(), (
				<>
				<h3 className={h3class} id="app-version">{t("generator.appversion-title")}</h3>
				<Validation isValid={isAppVersionValid} errorMessage={t("generator.appversion-error-msg")}>
					<input ref={appVersionRef} type="text" className={inputClass} value={appVersion} onChange={event => setAppVersion(event.target.value) } />
				</Validation>
				<p>{t("generator.appversion-desc")}</p>
				</>
			))}

            {d.ContactAddresses}

			<div id="content-type"></div>
			{d.ConsentType}

            {SelectTracker}

			<div id="checks"></div>
			<h3 className={h3class}>{t("generator.connections-to-title")} {showWhen(!d.isComplaint(), (<>{" (" + t("generator.optional") + ")"}</>))}</h3>
			{showWhen(!hasTested() && alreadyTriedToGenerate, (
				<p className={inputErrorMsgClass}>{t("generator.not-testet-error-msg")}</p>
			))}

			<p className={pClass} dangerouslySetInnerHTML={{
				__html: t("generator.connections-to-desc")
			}}></p>

			{showWhen(selectedTracker.length === 0, (
				<p className={pClass + " font-bold"}>{t("generator.no-tracker-selected")}</p>
			))}

			{showWhen(selectedTracker.length > 0, (
				<CheckBoxes
					classNames={{
						li: "border-t-2 border-gray-200 p-2",
						liLastChild: "border-b-2",
						label: "text-lg"
					}}
					key={Math.random()}
					checked={testetTrackersConnection.map(e => e)}
					onChange={ (checked: string[]) => { setTestetTrackersConnection(checked); }}
					options={sortTracker(selectedTracker, checkedTracker).map(tracker => {
						if (!(tracker["connections-to"])) tracker["connections-to"] = [];
						return {
						value: tracker.slug,
						label: tracker.name,
						after: (
							<>
								<p className={pClass + " text-sm"}>{t("generator.varies-by-region")} {joinArray(tracker["connections-to"].slice(0, 2), t("ge­ne­rell.common.or"))} </p>
							
								{showWhen(tracker["connections-to"].length > 1 && testetTrackersConnection.find(e => e === tracker.slug) ? true : false, 
									<AutoCompleteInput
										placeholder={t("generator.connections-to-placeholder") || ""}
										completions={tracker["connections-to"]}
										onBlur={onBlur}
										key={Math.random()}
										item={testetTrackersData.find(e => e.slug === tracker.slug) || null}
										onChange={value => {
											setTestetTrackersData(testetTrackersData.map(e => {
												e.lastChanged = false;
												if (e.slug === tracker.slug) {
													e.connectionTo = value;
													e.lastChanged = true;
												}
												return e;
											}))
										}}
										/>
								)}
							</>
						)
					}})}
					/>
			))}


			<h3 className={h3class}>{t("generator.sending-id-title") + " (" + t("generator.optional") + ")"}</h3>
			<p className={pClass} dangerouslySetInnerHTML={{
				__html: t("generator.sending-id-desc")
			}}></p>

			{showWhen(selectedTracker.length === 0, (
				<p className={pClass + " font-bold"}>{t("generator.no-tracker-selected")}</p>
			))}

			{showWhen(selectedTracker.length > 0, (
				<CheckBoxes
					classNames={{
						li: "border-t-2 border-gray-200 p-2",
						liLastChild: "border-b-2",
						label: "text-lg"
					}}
					checked={testetTrackersId.map(e => e)}
					onChange={ (checked: string[]) => { setTestetTrackersId(checked); }}
					options={sortTracker(selectedTracker, checkedTracker).map(tracker => {
						if (!tracker["sending-id-adresses"]) tracker["sending-id-adresses"] = [];
						return {
						value: tracker.slug,
						label: tracker.name,
						after: (
							<>
								<p className={pClass + " text-sm"}>{t("generator.sending-id-example")} {joinArray(tracker["sending-id-adresses"].slice(0, 2), t("ge­ne­rell.common.or"))} </p>
								{showWhen(testetTrackersId.find(e => e === tracker.slug) ? true : false, (
									<DynamicInputs
										onBlur={onBlur}
										placeholder={t("generator.sending-id-placeholder") || ""}
										item={testetTrackersData.find(e => e.slug === tracker.slug) || null}
										onChange={(v,i) => updateTestetTrackersData(tracker.slug, v, i) }
										/>
								))}
							</>
						)
					}})}
					/>

			))}


			{showWhen(d.isComplaint(), (
                <>
                <h3 className={h3class}>{t("generator.more-title")}</h3>
                <p className={pClass}></p>

                <CheckBoxes
					classNames={{
						ul: "max-w-xl",
						div: "_ items-start"
					}}
                    onChange={setMoreOptions}
                    checked={moreOptions.map(e => String(e))}
                    options={(t("generator.more-options", {
                        returnObjects: true,
                        interpolation: { escapeValue: false }
                    }) as any)}
                    />
                <br />
                </>
            ))}


            {d.LastCheckDate}

            {d.AddressOfPerson}

			{showWhen(d.consentType !== "ja", d.IncludeInTracktorList )}

            <br />
 			<button onClick={checkAndShowGeneratedText} className="btn-primary">
				{t("generator.generate-" + ((d.isComplaint()) ? "complaint" : "request"))}
 			</button>

        </>

	)

}
