import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { h3class, pClass } from './assets/style/clasnames';
import { getDefaultTemplateComplaintOptions, useSelectTracker, useDefaultGenerator } from './ComplaintGenerator';
import { getHostname, joinArray, scrollAndFocus, showWhen } from './utils/utils';

import CheckBoxes from "./components/checkboxes"

import webTrackers from "./data/tracker-webpage"

import Template from "letter-generator/Template"
import i18n from './utils/i18n';

let isFirstLoad = true;

export default function (props: {
	showGeneratedText: any,
	setShowGeneratedText: any
}) {

	i18n.setDefaultNamespace("webpage");
    const [t] = useTranslation();

	let d = useDefaultGenerator();

    const [loadedFromUrl, setLoadedFromUrl] = useState(false);
    const [checkedBelege, setCheckedBelege] = useState(([] as string[]));

    const {
		SelectTracker,
		checkedTracker,
		setCheckedTracker,
		customTrackerValues,
		setCustomTrackerValues,
		setSelectedTrackerIsValid 
	} = useSelectTracker(webTrackers, loadedFromUrl, (
		<>
			<p className={pClass}>{t("generator.network-analysis")}</p>

			<a className="font-medium text-1xl underline" href="/help.php?page=webpages" target="_blank" rel="noopener noreferrer">
				{t("generator.network-analysis-link")}
			</a>
		</>
	));

	function getRequestText (): string {

		const templateText = d.getTemplate()["website-tracking-request"];
		const defaults = d.getDefaultTemplateOptions(webTrackers, checkedTracker, customTrackerValues);

		return new Template(templateText, defaults.flags, defaults.variables).getText()// for scrolling

	}

	function getComplaintText (): string {

		const templateText = d.getTemplate()["website-tracking-complaint"];
		const defaults = d.getDefaultTemplateOptions(webTrackers, checkedTracker, customTrackerValues);

		const variables = {
			...defaults.variables,
			lastCheckDate: d.lastCheckDate,
			addressSiteOperator: d.addressSiteOperator,
			documentText: joinArray(checkedBelege, t("ge­ne­rell.common.and")),
			trackerCount: checkedTracker.length
		}

		const flags = {
			...defaults.flags,
			...getDefaultTemplateComplaintOptions(d.checkedDisclosureOptions)
		};

		return new Template(templateText, flags, variables).getText();

	}


	
    function getGeneratedText () {
		return (d.isComplaint()) ? getComplaintText() : getRequestText();
	}
	
	// -- Input Validation -- 

	const isInputUrlValid = () => d.inputUrl.length > 12;
	const isAddressSiteOperatorValid = () => d.addressSiteOperator.indexOf("\n") !== -1 || d.selectedDSB.slug !== "notfound";
	

    function checkAndShowGeneratedText () {

		if (!isInputUrlValid()) {
			d.setInputUrlIsValid(false);
			return scrollAndFocus("insert-url", d.inputUrlRef);
		}

		if (d.isComplaint()) {

			if (!isAddressSiteOperatorValid()) {
				d.setAddressSiteOperatorIsValid(false);
				return scrollAndFocus("site-operator", d.siteOperatorRef);
			}

		}

		if (checkedTracker.length === 0) {
			setSelectedTrackerIsValid(false);
			return (window as any).location.hash = "#select-tracker"
		}

		if (d.consentType !== "ja") {

			// The statistics should not be counted up every time a change is made.
			let savedDomains = localStorage.getItem("generated-domains")?.split(",") || [];
			let domain = new URL(d.inputUrl).hostname + "-" + ((d.isComplaint()) ? "complaint" : "request");

			if (!savedDomains.find(e => e === domain)) {
				savedDomains.push(domain);
				localStorage.setItem("generated-domains", savedDomains.join(","));

				let data = {
					"type": "webpage",
					"data": {
						"url": (d.includeInTracktorList) ? d.inputUrl : "http://[hidden]", // -> must be an url
						"action": (d.isComplaint()) ? "complaint" : "request",
						"tracker_slugs": checkedTracker,
						"supervisory_authority_slug": d.selectedDSB?.slug
					}
				}

				fetch(`${(window as any).location.origin}/statistics/api.php`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify(data)
				}).then(e => e.json()).then(e => console.log(e))

			}

		}

		props.setShowGeneratedText(true);

	}

	useEffect(()=> {

		if (isInputUrlValid()) {
			d.setInputUrlIsValid(true);
		}
		if (isAddressSiteOperatorValid()) {
			d.setAddressSiteOperatorIsValid(true);
		}

	}, [d.inputUrl, d.addressSiteOperator])

	
	function getSavedUrl () {

		return `${(window as any).location.protocol}//${(window as any).location.host}/#${btoa(JSON.stringify({
			id: "r-web",
			a: d.complaintCountry,
			b: d.consentType,
			c: d.inputUrl,
			d: checkedTracker,
			e: customTrackerValues
		}))}`;

	}

    const GeneratedText = d.useGeneratedText(getGeneratedText, props.setShowGeneratedText, getSavedUrl, {
		subjectName: getHostname(d.inputUrl)
	});

    if (isFirstLoad) {
		isFirstLoad = false;
		try {

			const savedData = JSON.parse(atob((window as any).location.hash.replace("#", "")));

			if(savedData.id === "r-web") {

				d.restore(savedData);

				setCheckedTracker(savedData.d);
				setLoadedFromUrl(true);
				setCustomTrackerValues(savedData.e);

				setTimeout(() => {
					(window as any).location.hash = "generator";
				}, 100);

			}

		} catch (error) { }
	}


    if (props.showGeneratedText) {
        return GeneratedText;
    }

    return (
        <>

            {d.SelectType}

            {d.InputUrl}

            {d.ContactAddresses}

            {SelectTracker}

            {d.ConsentType}

            {d.LastCheckDate}

            {showWhen(d.isComplaint(), (
                <>
                <h3 className={h3class}>{t("generator.title-receipts")}</h3>
                <p className={pClass}></p>

                <CheckBoxes
                    onChange={setCheckedBelege}
                    checked={checkedBelege.map(e => String(e))}
                    options={(t("generator.receipts-options", {
                        returnObjects: true,
                        interpolation: { escapeValue: false }
                    }) as any)}
                    />
                <br />
                </>
            ))}

            {d.AddressOfPerson}

			{showWhen(d.consentType !== "ja", d.IncludeInTracktorList )}

            <br />
			<button onClick={checkAndShowGeneratedText} className="btn-primary">
				{t("generator.generate-" + ((d.isComplaint()) ? "complaint" : "request"))}
 			</button>

        </>

	)

}
