import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inputClass } from '../assets/style/clasnames';
import { showWhen } from '../utils/utils';

interface IOption  {
	value: string,
	body?: string | any,
	label: string | any,
	filterText?: string,
	after?: any,
	checkedLabel?: string
}

export default function (props: {
    options?: IOption[],
	getOptions?: {(filterBy: string): IOption[]},
	elementNextToSearch?: JSX.Element,
	classNames?: {
		li?: string,
		label?: string,
		liLastChild?: string,
		ul?: string,
		div?: string
	},
	checked: string[],
	onChange: {(checked: string[]): void}
}) {

	const [t] = useTranslation();
	let options = props.options || [];

	const [filterBy, setFilterBy] = useState("");

	if (props.getOptions) {
		options = props.getOptions(filterBy);
	}

	

	function onChange (changeEvent: any) {

		let checked = props.checked;
		const value: string = changeEvent.target.value;
		const indexOf = checked.indexOf(value);

		if (indexOf > -1) checked.splice(indexOf, 1)
		else checked.push(value);

		props.onChange(checked);

	}

	const id = "checkbox_" + Math.random();
	const optionsFiltered = options.filter(e => (!e.filterText || e.filterText.toLowerCase().indexOf(filterBy.toLowerCase()) > -1))


	return (
		<>

			{(options.length > 4) ? (
				<div className="flex">
					<input 
						className={inputClass + " block"} 
						placeholder={t("generator.filter-tracker") || ""} 
						value={filterBy} 
						onChange={event => setFilterBy(event.target.value)}
						/>

					{(props.elementNextToSearch) ? props.elementNextToSearch : null}
				</div>
			) : null}

			<ul className={(props.classNames?.ul || "")}>
				{optionsFiltered.map((option: any, index: number) => (
					<li key={Math.random()} className={"px-2 " + (props.classNames?.li || "") + " " + ((index === optionsFiltered.length-1) ? props.classNames?.liLastChild || "" : "")}>
						<div className={"flex items-center" + (props.classNames?.div || "")}>
							<input className="m-1" checked={(props.checked.indexOf(option.value)) > -1} value={option.value} onChange={onChange} type="checkbox" id={id + index} />
							<label className={"pl-1 "} htmlFor={id + index}>
								<p className={"line-break font-medium " + (props.classNames?.label || "")}>{option.label}</p>
								{showWhen(props.checked.indexOf(option.value) > -1 && option.checkedLabel, (
									<span style={{ color: "red" }}>{option.checkedLabel}</span>
								))}
							</label>

						</div>
						<div className="pl-7">
							{option.body}
							{(option.after) ? option.after : null}
						</div>
					</li>
				))}
			</ul>

		</>
	)

}
