import React from 'react';

export function RadioButton(props: {
	onChange: {(value: string): void},
	checkedValue: string,
	label?: string | null | undefined,
	children?: any
	value: string,
	checkedLabel?: string | null
}) {

	let checked = false;
	if (props.checkedValue === props.value) checked = true;

	const randId: string = String(Math.random());

	return (
		<div className="flex items-start max-w-xl" >
			<input className="m-1" type="radio" value={props.value} checked={checked} id={randId} onChange={(event: any)=>{props.onChange(event.target.value)}} />
			<label htmlFor={randId}>
				<p className="line-break pl-1 font-medium">
					{(props.label) ? props.label : null}
					{(props.children) ? props.children : null}
					{(checked && props.checkedLabel) ? (
						<>
						<br />
						<span style={{ color: "red" }}>{props.checkedLabel}</span>
						</>
					) : null}
				</p>
			</label>
		</div>
	)

}

export function RadioButtons  (props: {
	onChange: {(value: string): void},
	checkedValue: string,
	items: {
		label?: string | null,
		children?: any
		value: string,
		checkedLabel?: string | null
	}[]
}) {

	return (

		<div className="mt-4 mb-2">

			{(props.items.map(item => (
				<div key={Math.random()}>
					<RadioButton onChange={props.onChange} checkedLabel={item.checkedLabel} value={item.value} label={item.label || ""} checkedValue={props.checkedValue} >
						{item.children}
					</RadioButton>
					<div className="h-2"></div>
				</div>
			)))}

		</div>

	)

}