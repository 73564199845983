import React from 'react';
import { InputItem } from '../AndroidApp';
import { inputClass } from '../assets/style/clasnames';
import { showWhen } from '../utils/utils';

export function AutoCompleteInput (props: {
	completions: string[],
	placeholder?: string | null,
	item: InputItem | null,
	onChange: {(input: string): void },
	onBlur: {(): void}
}) {

	const value = props.item?.connectionTo || "";

	function handleEvent(event: React.ChangeEvent<HTMLInputElement>) {
		props.onChange(event.target.value);
	}

	const completions = props.completions.filter(completion => completion.toLowerCase().indexOf(value.toLowerCase()) > -1 && completion !== value);

	return (
		<>
		<input
			autoFocus={((props.item?.lastChanged) ? true : false)}
			className={inputClass}
			placeholder={(props.placeholder || "")}
			onBlur={props.onBlur}
			type="text"
			onChange={handleEvent}
			value={value} 
			/>

		{showWhen(completions.length > 0 && value.length > 0, (
			<ul className="max-h-44 overflow-auto border-2 border-primary max-w-xl px-4 py-2 rounded">
				{completions.map((completion, index) => (
					<li className={inputClass + " cursor-pointer"} key={index}
						data-completion={completion}
						onClick={e => props.onChange((e.target as any).dataset["completion"])}
						>
						{completion}
					</li>
				))}
			</ul>
		))}
		</>
	)

}