import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { copy } from "../utils/utils"

export default function CopyIcon (props: {copytext: string}) {

	const [t] = useTranslation();
	const [copied, setCopied] = useState(false);

	function copyTrackerUrl (event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

		const target = event.currentTarget;

		copy(target.dataset["copy"] || "");

		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 3000);

	}
	
	return (
		<div className="" title={t("generator.copy-url") || ""} data-copy={props.copytext} onClick={copyTrackerUrl}>
			{(copied) ? (
				<svg className="fill-current text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z"/></svg>
			) : (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 1H4c-1.1 0-2 .9-2 2v13c0 .55.45 1 1 1s1-.45 1-1V4c0-.55.45-1 1-1h10c.55 0 1-.45 1-1s-.45-1-1-1zm4 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-1 16H9c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h9c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1z"/></svg>
			)}
		</div>
	)

}