import React, { useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { RadioButtons } from "./components/radiobutton"
import CheckBoxes from "./components/checkboxes"
import Validation from "./components/Validation"
import { CustomTrackerValues, getTrackerItems } from './components/trackeritem'

import { showWhen, getSA, generateICSFile, copyToClipboard, openEmailProgram, noSAfound } from "./utils/utils"
import { h3class, inputClass, inputErrorMsgClass, pClass } from "./assets/style/clasnames"

import dbTemplates from "./data/templates"
import { SupervisoryAuthority } from './data/supervisory-authorities.d'
import ITracker from './data/tracker-webpage.d'
import i18n from './utils/i18n'
import { TFunction } from 'i18next'

export function useDefaultGenerator () {

    const [complaintCountry, setComplaintCountry] = useState("de");
    const [selectedDSB, setSelectedDSB] = useState(noSAfound);

	const { SelectType, isComplaint, setGeneratorType } = useSelectType();
	const [t] = useTranslation();


    const d = {
        complaintCountry, setComplaintCountry,
        selectedDSB, setSelectedDSB,
        SelectType, isComplaint, setGeneratorType,
        ...useContactAddresses(isComplaint, complaintCountry, setSelectedDSB),
        ...useAddressOfPerson(isComplaint),
        ...useLastCheckDate(isComplaint),
        ...useInputUrl(),
        ...useConsentType(),
		...useIncludeInTracktorList(),
        getDefaultTemplateOptions: (dbTrackers: ITracker[], checkedTracker: string[], customTrackerValues: CustomTrackerValues[]) => {

            return getDefaultTemplateOptions (
				t,
                checkedTracker,
                d.consentType,
                d.inputUrl,
                dbTrackers,
                d.addressOfThePersonConcerned,
                d.isComplaint,
                customTrackerValues,
				d.selectedConsentButtons
            );

        },
        restore: (savedData: any) => {

            d.setComplaintCountry(savedData.a);
            d.setGeneratorType("complaint");
            d.setConsentType(savedData.b);
            d.setInputUrl(savedData.c);

		},
		useGeneratedText: (getGeneratedText: {():string}, setShowGeneratedText: any, getSavedUrl: {(): string}, options?: {
			withDeadline?: boolean,
			subjectName: string
		} ) => {
			return useGeneratedText (
				getGeneratedText,
				d.consentType,
				d.emailPrivacyOfficer,
				d.isComplaint,
				setShowGeneratedText,
				d.selectedDSB,
				d.inputUrl,
				getSavedUrl,
				options
			)
		},
		getTemplate () {

			let lang = (i18n.options.lng || "----");
			return ((dbTemplates as any)[lang]) ?  (dbTemplates as any)[lang] : dbTemplates["de"]

		}
    };


    return d;

}

export function getDefaultTemplateComplaintOptions (
	checkedDisclosureOptions: string[]
) {
	return {
		useDisclosure: checkedDisclosureOptions.length > 0,
		bothDisclosureEnabled: checkedDisclosureOptions.length === 2,
		disclosureUnencryptedMailAllow: checkedDisclosureOptions.indexOf("unencryptedMailAllow") > -1,
		discloseNamesForTheRespondent: checkedDisclosureOptions.indexOf("discloseNamesForTheRespondent") > -1
	}
}

export function getDefaultTemplateOptions (
	t: TFunction,
	checkedTracker: string[],
	consentType: string,
	inputUrl: string,
	dbTrackers: ITracker[],
	addressOfThePersonConcerned: string,
	isComplaint: {(): boolean},
	customTrackerValues: CustomTrackerValues[],
	selectedConsentButtons: string
) {

	return {
		flags: {
			useplural: checkedTracker.length !== 1,
			usesingular: checkedTracker.length === 1,
			consented: consentType === "ja",
			consentedMinimal: consentType === "minimal",
			selectedConsentButtons: (consentType === "minimal" && selectedConsentButtons.length > 0),
			notConsented: consentType === "nein",
		},
		variables: {
			trackerUrl: inputUrl,
			bghRuling: t("templates-blocks.bgh-ruling"),
			trackerText: getTrackerText(dbTrackers, isComplaint, checkedTracker, customTrackerValues),
			addressOfThePersonConcerned,
			selectedConsentButtons
		}
	}

}


export function useSelectType () {

	const [t] = useTranslation();
	const [generatorType, setGeneratorType] = useState("request"); // request, complaint
	const isComplaint = (): boolean => generatorType === "complaint";

	return {
		SelectType: (
			<>
			<h3 className={h3class}>{t("generator.title-already-sent")}</h3>
			<p className={(isComplaint() ? "font-bold " : "") + pClass}>{t("generator.complaint-info")}</p>

			<RadioButtons onChange={setGeneratorType} checkedValue={generatorType} items={[
				{ value: "request", label: t("generator.option-request")},
				{ value: "complaint", label: t("generator.option-complaint")},
			]} />
			</>
		),
		isComplaint,
		setGeneratorType
	};

}

export function useInputUrl () {

	const [t] = useTranslation();
	const inputUrlRef = useRef((null as any));
	const [isValid, setInputUrlIsValid] = useState(true);
	const [inputUrl, setInputUrl] = useState((t("generator.insert-url-default") as string));

	return {
		InputUrl: (
			<>
				<h3 className={h3class} id="insert-url">{t("generator.title-insert-url")}</h3>
				<Validation isValid={isValid} errorMessage={t("generator.insert-url-error-msg")}>
					<input ref={inputUrlRef} type="text" className={inputClass} value={inputUrl} onChange={event => setInputUrl(event.target.value) } />
				</Validation>
			</>
		),
		inputUrl,
		setInputUrl,
		inputUrlRef,
		setInputUrlIsValid
	}

}

export function useConsentType () {
	const [t] = useTranslation();
	const [consentType, setConsentType] = useState("ja"); // ja, nein, minimal
	const [selectedConsentButtons, setSelectedConsentButtons] = useState("");
	const [isValid, setConsentTypeIsValid] = useState(true);
	
	return {
		ConsentType: (
			<>
				<h3 className={h3class}>{t("generator.title-consent")}</h3>
				<p  className={pClass} dangerouslySetInnerHTML={{__html: t("generator.desc-consent")}}></p>

				{showWhen(!isValid, (
					<p className={inputErrorMsgClass}>{t("generator.consent-error-msg")}</p>
				))}

				<RadioButtons onChange={setConsentType} checkedValue={consentType} items={(t("generator.consent-options", {
					returnObjects: true,
					interpolation: { escapeValue: false }
				}) as any)} />

				{showWhen(consentType === "minimal", (
					<div className="_ml-8">
						<p className={pClass} style={{
							margin: "0"
						}}>{t("generator.consent-buttons-title")}</p>
						<input className={inputClass} type="text"
							placeholder={t("generator.consent-buttons-placeholder") || ""}
							value={selectedConsentButtons}
							onChange={(event)=>{setSelectedConsentButtons(event.target.value)}}
							/>
					</div>
				))}
			</>
		),
		consentType,
		selectedConsentButtons,
		setConsentType,
		setConsentTypeIsValid
	}
}

export function useContactAddresses(isComplaint: {(): boolean}, complaintCountry: string, setSelectedDSB: React.Dispatch<React.SetStateAction<SupervisoryAuthority>>) {

	const [t] = useTranslation();
	const siteOperatorRef = useRef((null as any));

	const [isValid, setAddressSiteOperatorIsValid] = useState(true);
	const [isBFDI, setIsBFDI] = useState(false);
	const [addressSiteOperator, setAddressSiteOperator] = useState("");
	const [emailPrivacyOfficer, setEmailPrivacyOfficer] = useState("");

	function changeOperatorAddress (event: React.ChangeEvent<HTMLTextAreaElement>): void {

		setAddressSiteOperator(event.target.value);
		setSelectedDSB(getSA(complaintCountry, event.target.value, isBFDI));

	}

	function changeIsBFDI (event: any) {

		setIsBFDI(event.target.checked);
		setSelectedDSB(getSA(complaintCountry, addressSiteOperator, event.target.checked));

	}

	return {
		ContactAddresses: (
			isComplaint() ? (
				<>
					<h3 className={h3class} id="site-operator">{t("generator.title-address-site-operator")}</h3>
					<p className={pClass}>{t("generator.address-note")}</p>
					<Validation errorMessage={t("generator.address-error-msg")} isValid={isValid}>
						<textarea ref={siteOperatorRef} value={addressSiteOperator} className={inputClass} cols={40} rows={4} onChange={changeOperatorAddress}></textarea>
					</Validation>
					<p className="max-w-xl">{t("generator.address-location")}</p>
					{showWhen(complaintCountry === "de", (
						<p className="max-w-xl line-break flex m-0 mb-4 mt-3 flex-center self-center hidden sm:block">
							<input id="is-bfdi" type="checkbox" className='mr-2' checked={isBFDI} onChange={changeIsBFDI} />
							<label htmlFor="is-bfdi" className="mr-2 text-s">
								{t("generator.check-is-bfdi")}
							</label>
						</p>
					))}
				</>
			) : (
				<>
					<h3 className={h3class}>{t("generator.title-email-privacy-officer")}</h3>
					<input type="email" className={inputClass} value={emailPrivacyOfficer} onChange={event => setEmailPrivacyOfficer(event.target.value) } />
					<br />
					<p className={pClass}>{t("generator.email-location")}</p>
				</>
			)
		),
		addressSiteOperator,
		emailPrivacyOfficer,
		siteOperatorRef,
		setAddressSiteOperatorIsValid
	}

}

export function sortTracker (tracker: ITracker[], checkedTracker: string[], fixSelectedTracker: boolean = false) {

	return tracker.sort((a, b) => {
		if (fixSelectedTracker) {
			const ac = checkedTracker.find(slug => slug === a.slug);
			const bc = checkedTracker.find(slug => slug === b.slug);
			if (ac && !bc) return -1;
			if (!ac && bc) return 1;
		}
		if (a.priority === b.priority) {
			return (a.name > b.name) ? -1 : 1;
		}
		return (a.priority > b.priority) ? -1 : 1;
	})

}

export function useSelectTracker (dbTrackers: ITracker[], loadedFromUrl: boolean, description: JSX.Element) {

	const [t] = useTranslation();
	const [checkedTracker, setCheckedTracker] = useState(([] as string[]));
	const [searchInTracker, setSearchInTracker] = useState(false);
	const [showAllTracker, setShowAllTracker] = useState(false);
	const [isValid, setSelectedTrackerIsValid] = useState(true);
	const [fixSelectedTracker, setFixSelectedTracker] = useState(false);

	const [customTrackerValues, setCustomTrackerValues] = useState(([] as CustomTrackerValues[]));

	function updateCustomInputsValues (value: string, tracker: ITracker) {

		const updateDomain = customTrackerValues.find(e => e.trackerSlug === tracker.slug);
		if (!updateDomain) customTrackerValues.push({trackerSlug: tracker.slug, input: value, lastChanged: true});

		setCustomTrackerValues(customTrackerValues.map((cname) => {
			cname.lastChanged = false;
			if (cname.trackerSlug === tracker.slug) {
				cname.input = value;
				cname.lastChanged = true;
			}
			return cname;
		}));

	}

	function getFilteredTracker () {
		let tracker = dbTrackers;
		return tracker.filter(e => e.priority >= 50 || checkedTracker.find(slug => slug === e.slug))
	}

	function getTrackerForCheckbox (filter: boolean) {

		let tracker = dbTrackers;
		if (filter && !showAllTracker && dbTrackers.length > 5) {
			tracker = getFilteredTracker()
		}
		return getTrackerItems({
			t,
			dbTrackers: sortTracker(tracker, checkedTracker, fixSelectedTracker),
			checkedTracker,
			customTrackerValues,
			updateCustomInputsValues,
			setCustomTrackerValues
		})

	}

	useEffect(() => {

		if (checkedTracker.length > 0) {
			setSelectedTrackerIsValid(true);
		}

	}, [checkedTracker])


	return {
		SelectTracker: ( <>
			<h3 className={h3class} id="select-tracker">{t("generator.title-tracker-found")}</h3>
			{showWhen(!isValid, (
				<p className={inputErrorMsgClass}>{t("generator.error-no-tracker-selected")}</p>
			))}

			{description}

			{showWhen(loadedFromUrl, (
				<p className={pClass + " bg-yellow-200 font-medium text-yellow-900 p-4 w-max"}>{t("generator.warning-configfromurl")}</p>
			))}

			<CheckBoxes
				onChange={ (checked: string[]) => { setCheckedTracker(checked); }}
				checked={checkedTracker.map(e => e)}
				elementNextToSearch={<>
					<div className="flex m-0 ml-auto flex-center self-center hidden sm:block">
						<label htmlFor="fix-selected-tracker" className="mr-2">{t("generator.fix-selected-tracker")}</label>
						<input id="fix-selected-tracker" checked={fixSelectedTracker} type="checkbox" onChange={e => setFixSelectedTracker(e.currentTarget.checked)}  />
					</div>
				</>}
				classNames={{
					li: "border-t-2 border-gray-200 p-2",
					liLastChild: "border-b-2",
					label: "text-lg"
				}}
				getOptions={(filterBy) => {
					const a = filterBy === "";
					setTimeout(() => {
						setSearchInTracker(!a);
					}, 0);
					return getTrackerForCheckbox(a);
				}}
				/>

			{(searchInTracker || dbTrackers.length < 5 || (dbTrackers.length - getFilteredTracker().length) === 0) ? null : (
				<button className="link text-xl mt-4" onClick={_ => setShowAllTracker(!showAllTracker)}>
					{showAllTracker ?
						t("generator.show-common-tracker") :
						t("generator.show-all-tracker", {count: (dbTrackers.length - getFilteredTracker().length)})
					}
				</button>
			)}

		</>),
		checkedTracker,
		setCheckedTracker,
		customTrackerValues,
		setCustomTrackerValues,
		setSelectedTrackerIsValid

	}

}

export function useGeneratedText (
	getGeneratedText: {(): string},
	consentType: string,
	emailPrivacyOfficer: string,
	isComplaint: {(): boolean},
	setShowGeneratedText: {(show: boolean): void},
	selectedDSB: SupervisoryAuthority,
	inputUrl: string,
	getSavedUrl: {(): string},
	options?: {
		withDeadline?: boolean,
		subjectName: string
	}
 ) {

	const [t] = useTranslation();

	const getActiveToEmail = (): string => (isComplaint()) ? (selectedDSB.email || t("generator.no-email-found")) : emailPrivacyOfficer;
	function getSubject (): string  {
		let sub = t(`generator.${((isComplaint()) ? "complaint" : "request") }-subject`, {
			domain: options?.subjectName || ""
		})
		if (options?.withDeadline && !isComplaint()) {
			sub += " " + t(`generator.with-deadline`);
		}
		return sub;
	}

	function createReminder () {

		generateICSFile(t, inputUrl, getSavedUrl());

	}

	return (
		<>
			<h3 className={h3class} id="nachricht">
				{t(`generator.title-message`)}
			</h3>

			{(consentType === "ja") ? (
				<p className={pClass + " bg-yellow-200 font-medium text-yellow-900 p-4"}>
					{t(`generator.consent-warning`)}
				</p>
			) : null}

			<p className={"_" + pClass}>
				{t(`generator.mail-to`)}: <a href={"maito:"+getActiveToEmail()}>{getActiveToEmail()}</a><br />
				{t(`generator.mail-subject`)}: <b>{getSubject()}</b>
			</p>

			<div className="line-break max-h-96 my-6 p-5 bg-gray-100 overflow-auto border-gray-300 border-t-2 border-b-2 leading-relaxed">
				{getGeneratedText()}
				<br />
				<br />
			</div>

			{showWhen(isComplaint(),
				<>
					<h3 className={h3class}>
						{t(`generator.moreinfos-title`)}
					</h3>
					<p className={pClass + "mb-2"}>
						{t(`generator.responsible-dpo`, { dsb: `"${selectedDSB.name}"` })}
						<br />
						{(selectedDSB.slug !== "notfound" && selectedDSB["online-complaint-form"]) ? (
							<a className="underline" href={selectedDSB["online-complaint-form"]} target="_blank" rel="noopener noreferrer" >
								{t(`generator.complaint-form`)}
							</a>
						) : null}
					</p>
				</>
			)}


			<ul className="flex flex-col space-y-3">

				<li>
					<button
            className={"btn-primary w-max"}
            onClick={_ => openEmailProgram(getActiveToEmail(), getSubject(), getGeneratedText())}
          >
            {t("generator.action-open-mail")}
          </button>
				</li>
				<li>
          <button
            className={"btn-secondary w-max"}
            onClick={_ => {copyToClipboard(getGeneratedText());alert(t("ge­ne­rell.message-copied"));}}
          >
					  {t("generator.action-copy")}
          </button>
				</li>
				{showWhen((!isComplaint() && options?.withDeadline !== false),
					<li>
            <button
              className={"btn-secondary w-max"}
              onClick={_ => createReminder()}
            >
				      {t("generator.action-reminder")}
            </button>
					</li>
				)}

			</ul>
			{showWhen(!isComplaint(), (
				<p className={pClass}>
					<a className="underline" href={getSavedUrl()} target="_blank" rel="noopener noreferrer" >
						{t(`generator.saved-url`)}
					</a><br />
					{t(`generator.saved-url-hint`)}
				</p>
			))}

			<button
        className={pClass + " link flex items-center"}
        onClick={__ => setShowGeneratedText(false)}
      >
				<span className="icon arrow-back fill-current text-primary"></span>{t("generator.back-to-data-entry")}
			</button>

		</>
	)
}


export function getTrackerText (
	dbTrackers: ITracker[],
	isComplaint: {(): boolean},
	checkedTracker: string[],
	customTrackerValues: CustomTrackerValues[]
): string {

	let trackers = dbTrackers.filter(tracker => checkedTracker.find(slug => slug === tracker.slug));

	return trackers.map(tracker => {

		let text = tracker.template;

		if (tracker["complaint-template"] && isComplaint()) {
			text = tracker["complaint-template"];
		}
		if (tracker.customInput) {
			text = text.replace("{custom-input-value}", customTrackerValues.find(e => e.trackerSlug === tracker.slug)?.input || "");
		}

		return text;

	}).join("\n\n");

}

export function useAddressOfPerson (isComplaint: {(): boolean}) {

	const [t] = useTranslation();
	const [addressOfThePersonConcerned, setAddressOfThePersonConcerned] = useState("");
	const [checkedDisclosureOptions, setCheckedDisclosureOptions] = useState(
		[ "unencryptedMailAllow", "discloseNamesForTheRespondent"]
	);

	return {
		AddressOfPerson: (<>

			<h3 className={h3class + " mt-6"}>{t("generator.person-title")}</h3>
			<p className={pClass}>{t("generator.person-desc")}</p>

			<textarea className={inputClass} cols={40} rows={4}
			  placeholder={t("generator.person-placeholder") || ""}
			  value={addressOfThePersonConcerned}
			  onChange={ event => {setAddressOfThePersonConcerned(event.target.value)}}></textarea>

			{showWhen(isComplaint(), (

				<CheckBoxes
					onChange={ (checked: string[]) => {
						setCheckedDisclosureOptions(checked.map(e => e));
					}}
					checked={checkedDisclosureOptions.map(e => e) } // e => e | clone array
					options={[
						{
							label: t("generator.person-unencrypted-mail"),
							value: "unencryptedMailAllow"
						},
						{
							label: t("generator.person-disclose-name"),
							value: "discloseNamesForTheRespondent"
						}
					]} />
			))}

		</>),
		addressOfThePersonConcerned,
		checkedDisclosureOptions
	}

}

export function useLastCheckDate (isComplaint: {(): boolean}) {

	const [t] = useTranslation();
	const [lastCheckDate, setLastCheckDate] = useState(moment().format('DD.MM.YYYY') + " um ca. " + moment().format('HH') + ":00 Uhr");

	return {
		LastCheckDate: (
			showWhen(isComplaint(), (
				<>
					<h3 className={h3class}>{t("generator.title-time")}</h3>
					<input className={inputClass} type="text"
						value={lastCheckDate}
						onChange={(event)=>{setLastCheckDate(event.target.value)}}
						/>
				</>
			))
		),
		lastCheckDate
	}

}

export function useIncludeInTracktorList () {

	const [t] = useTranslation();
	const [includeInTracktorList, setIncludeInTracktorList] = useState(false);

	return {
		IncludeInTracktorList: (
			<>
		
				<h3 className={h3class}>{t("generator.include-in-list-title")}</h3>
				<p className={pClass}>{t("generator.include-in-list-desc")}</p>
		
				<CheckBoxes
					onChange={e => setIncludeInTracktorList(e[0] === "includeInTracktorList")}
					checked={(includeInTracktorList) ? [ "includeInTracktorList" ] : []}
					options={[
						{
							label: t("generator.include-in-list-label"),
							value: "includeInTracktorList"
						}
					]} />
		
			</>
		),
		includeInTracktorList
	}

}