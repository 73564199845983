import React from 'react';
import { inputWithErrorClass, inputErrorMsgClass } from '../assets/style/clasnames';
import { showWhen } from '../utils/utils';


export default function (props: {
    errorMessage: string,
    isValid: boolean,
    children?: JSX.Element
}) {

    const isInvalid = !props.isValid;
    let children = props.children;

    if (props.children && isInvalid) {
        children = React.cloneElement(props.children, {
            className: inputWithErrorClass
        })
    }

    return (
        <>
            {children}
            {showWhen(isInvalid, (
                <p className={inputErrorMsgClass}>{props.errorMessage}</p>
            ))}
        </>

    )


}