import React from 'react';
import { InputItem } from '../AndroidApp';
import { inputClass } from '../assets/style/clasnames';

export function DynamicInputs (props: {
	placeholder: string,
	item: InputItem | null,
	onChange: {(input: string, index: number): void },
	onBlur: {(): void}
}) {

	if (!props.item) return null;

	return (<>{props.item.sendingId.map((value, index) => (
		<input
			placeholder={props.placeholder || ""}
			key={index}
			onBlur={props.onBlur}
			autoFocus={((props.item?.lastChanged === index) ? true : false)}
			className={inputClass}
			value={value}
			data-index={index}
			onChange={event => props.onChange(event.target.value, parseInt(event.target.dataset["index"] || "-1"))}
			/>
	))}</>)
}