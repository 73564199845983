import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import localizations from "../data/localization"

i18n

    .use(initReactI18next)
    .init({
        lng: (document.documentElement.lang) ? document.documentElement.lang : 'de',
        fallbackLng: 'de',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        ns: ['app', 'webpage'],
        defaultNS: 'webpage',
        resources: {
            de: {
                webpage: {
                    ...localizations["de"],
                    generator: localizations["de"]["gen-webpage"]
                },
                app: {
                    ...localizations["de"],
                    generator: localizations["de"]["gen-app"]
                }
            },
            en: {
                webpage: {
                    ...localizations["en"],
                    generator: localizations["en"]["gen-webpage"]
                },
                app: {
                    ...localizations["en"]
                }
            }
        }
    });

export default i18n;