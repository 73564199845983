import React, { useState } from 'react';

import CopyIcon from "./copyicon"

import ITracker from "../data/tracker-webpage.d";

import { TFunction } from 'i18next';
import { inputClass } from "../assets/style/clasnames"
import { showWhen } from '../utils/utils';
import { useTranslation } from 'react-i18next';

export interface ITrackers extends Array<ITracker>{}

export interface CustomTrackerValues {
	trackerSlug: string,
	input: string;
	lastChanged: boolean
}

interface ITrackerProps {
    t: TFunction,
    updateCustomInputsValues: (input: string, tracker: ITracker) => void,
    setCustomTrackerValues: React.Dispatch<React.SetStateAction<CustomTrackerValues[]>>,
    customTrackerValues: CustomTrackerValues[],
    checkedTracker: string[]
}

interface ITrackerItem {
    functions: ITrackerProps,
    tracker: ITracker,
    index: number
}

function TrackerItem (props: ITrackerItem) {

    const { customTrackerValues, checkedTracker, t, updateCustomInputsValues, setCustomTrackerValues } = props.functions;

    const cnameCloakingDomain = customTrackerValues.find(e => e.trackerSlug === props.tracker.slug);
    const urls = props.tracker["tracking-urls"];

    return (
        <>

        {(typeof props.tracker["search-by"] === "object" ? (
            <div className="items-center space-x-2">
                <span>{t("generator.search-networktraffic")}</span>
                {props.tracker["search-by"]?.map((searchBy, index: number) => (

                    <div key={index} className="flex items-center space-x-3">

                        <span className="flex items-center space-x-2 font-light"><b>{searchBy}</b> <CopyIcon copytext={searchBy} /></span>

                        {(index < (props.tracker["search-by"]?.length||1)-1) ? (
                            (index === (props.tracker["search-by"]?.length||2)-2) ? ' ' + t("ge­ne­rell.common.or") : ', '
                        ) : null}

                        <br />

                    </div>

                ))}
            </div>
        ) : (
            urls?.map((url, index: number) => (

                <div key={index} className="flex items-center space-x-3">
    
                    <span className="flex items-center space-x-2 font-light"><b>{url}</b> <CopyIcon copytext={url} /></span>
    
                    {(index < urls.length-1) ? (
                        (index === urls.length-2) ? ' ' + t("ge­ne­rell.common.or") : ', '
                    ) : null}
    
                    <br />
    
                </div>
    
            ))
        ))}

        {showWhen((props.tracker.customInput || false) && (checkedTracker.find(slug => slug === props.tracker.slug) ? true : false) , (

            <input
                className={inputClass}
                autoFocus={(cnameCloakingDomain?.lastChanged) ? true : false}
                placeholder={t("generator.custom-input-placeholder") || ""}
                type="text"
                value={cnameCloakingDomain?.input}
                data-domainid={props.index}
                onBlur={e => setCustomTrackerValues(customTrackerValues.map(e => {
                    e.lastChanged = false;
                    return e;
                }))}
                onChange={e => updateCustomInputsValues(e.target.value, props.tracker)}/>

        ))}

        </>
    );
}

interface IgetTrackerItems extends ITrackerProps {
    dbTrackers: ITrackers
}

function TrackerDetails (props: {tracker: ITracker, checked: boolean}) {

    const [showDetails, setShowDetails] = useState(false);
    const [t] = useTranslation();

    return (
        <>

            <button className="link whitespace-nowrap my-2" onClick={_ => setShowDetails(!showDetails)}>
                <span className="w-4 inline-block">{showDetails ? '-' : '+'}</span>{showDetails ? t("generator.hide-details") : t("generator.show-details")}
            </button>

            {showWhen(showDetails, (
                <p className="text-sm line-break mb-2 leading-relaxed">
                    {props.tracker.template}
                </p>
            ))}

            {showWhen((props.tracker.note ? true : false) && props.checked, (
                <p className="bg-yellow-200 text-yellow-900 font-medium p-4"> {props.tracker.note?.text} </p>
            ))}

        </>
    )

}

export function getTrackerItems (props: IgetTrackerItems) {

    return props.dbTrackers.map((tracker: ITracker, index: number) => {

        return {
            value: tracker.slug,
            label: tracker.name,
            body: <TrackerItem tracker={tracker} index={index} functions={props} />,
            filterText: tracker.name + (tracker["tracking-urls"]?.join(" ") || ""),
            after: <TrackerDetails tracker={tracker} checked={props.checkedTracker.find(e => e === tracker.slug) ? true : false} />
        }
    })

}
